// Import CSS
// import '../css/gijgo.min.css';
// import '../css/theme.default.min.css';
// import '../css/theme.dark.min.css';
// import '../css/theme.bootstrap_4.min.css';
// import './addons/pager/jquery.tablesorter.pager.css';
// import 'simplelightbox/dist/simple-lightbox.css';
// import 'datatables.net/css/datatables.min.css';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.min.css';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import 'dropzone/dist/dropzone.css';
import '../bootstrap/css/bootstrap.min.css';
import '../css/styles.css';
import '../css/myStyles.css';
// Import JS
// import 'cordova'; // Done in index.html => VoltBuilder !
import * as bootstrap from 'bootstrap';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// import './fonts.js';
// import * as feather from 'feather-icons'; // if not imported with webpack.ProvidePlugin
// import './font-awesome-5.11.2-all.min.js';
import Chart from 'chart.js/auto'; // https://www.chartjs.org/docs/latest/
import './jquery.html5storage.min.js';
// import './datatables.min.js';
// import '../demo/chart-area-demo.js';
// import '../demo/chart-bar-demo.js';
// import '../demo/chart-pie-demo.js';
// import '../demo/datatables-demo.js';
// import './theme.min.js';
import datatablesFrench from './datatables_fr_FR.json';
import jszip from 'jszip';
window.JSZip = jszip;
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net';
import 'datatables.net-bs5';
// import 'datatables.net-autofill-bs5';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons-bs5';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-colreorder-bs5';
import 'datatables.net-datetime';
// import 'datatables.net-fixedcolumns-bs5';
import 'datatables.net-fixedheader-bs5';
// import 'datatables.net-keytable-bs5';
// import 'datatables.net-responsive-bs5';
// import 'datatables.net-rowgroup-bs5';
// import 'datatables.net-rowreorder-bs5';
// import 'datatables.net-scroller-bs5';
import 'datatables.net-searchbuilder-bs5';
// import 'datatables.net-searchpanes-bs5';
// import './jquery.easing.min.js';
// import './gijgo.fr.min.js';
// import './jquery.tablesorter.min.js';
// import './jquery.tablesorter.widgets.min.js';
// import './widget-cssStickyHeaders.min.js';
// import './addons/pager/jquery.tablesorter.pager.min.js';
// import './lightbox.rotate.img.js';
// import './smooth.scroll.anchor.js';
// import './FileSaver.min.js';
// import 'tableexport';
import Dropzone from 'dropzone';
// import SimpleLightbox from "simplelightbox";
// import simpleLightbox from "simpleLightbox/dist/simple-lightbox.jquery";
import regeneratorRuntime from "regenerator-runtime"; // Async/Await made available here
import 'litepicker'; // https://wakirin.github.io/Litepicker
import 'litepicker/dist/plugins/ranges';
// import 'litepicker/dist/plugins/mobilefriendly';
// import 'dark-mode-switch'; // https://github.com/coliff/dark-mode-switch
// import hotkeys from 'hotkeys-js'; // https://github.com/jaywcjlove/hotkeys-js
import Swal from 'sweetalert2' // https://sweetalert2.github.io/


// Set new default font family and font color to mimic Bootstrap's default styling
// Chart.defaults.global.defaultFontFamily = 'Metropolis, -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
// Chart.defaults.global.defaultFontColor = "#858796";

var globals,
App = {
	settings: {
		devicePlatform: '',
		bottomScrolled: false,
		myPage: '',
		isMobile: false,
		serverAddress: "https://app.snsolutions.fr/db/appController.php",
		serverBaseUrl: "https://app.snsolutions.fr/",
		year: new Date().getFullYear(),
		today: new Date(),
		langages: '',
		datatablesFrench: '',
		interventionsRefresh: true,
		interventionsRefresher: false,
		interventionsRefresherTimer: 60000,
		interventionsListsToRefresh: ['getInterventionsList', 'getInterventionsListCCJ'],
		currentInterventionsFilterClient: '',
		defaultIdClient: 0,
		defaultProblem: 'Nb de personnes impactées : \r\nDepuis quand : \r\nObjet détaillé de la demande : ',
		tomorrow: new Date(new Date().setDate(new Date().getDate() + 1)),
		nextSevenDays: new Date(new Date().setDate(new Date().getDate() + 7)),
		nextThirtyDays: new Date(new Date().setDate(new Date().getDate() + 30)),
		thisMonthStart: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
		thisMonthEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
		nextMonthStart: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
		nextMonthEnd: new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0),
		pass: $.localStorage.getItem('pass'),
		login: $.localStorage.getItem('login'),
		pwd: $.localStorage.getItem('pwd'),
		id: $.localStorage.getItem('id'),
		active: $.localStorage.getItem('active'),
		type: $.localStorage.getItem('type'),
		mail: $.localStorage.getItem('mail'),
		created: $.localStorage.getItem('created'),
		lastmod: $.localStorage.getItem('lastmod'),
		lastcon: $.localStorage.getItem('lastcon'),
		clients: $.localStorage.getItem('clients'),
		clientsInfos: $.sessionStorage.getItem('clientsInfos'),
		// adminPass: $.sessionStorage.getItem('adminPass'),
	},
	refreshGlobals: function(data) {
		globals.pass = data.pass;
		globals.login = data.login;
		globals.pwd = data.pwd;
		globals.id = data.id;
		globals.active = data.active;
		globals.type = data.type;
		globals.mail = data.mail;
		globals.created = data.created;
		globals.lastmod = data.lastmod;
		globals.lastcon = data.lastcon;
		globals.clients = data.clients;
		globals.clientsInfos = data.clientsInfos
		// for (const [key, value] of Object.entries(data.clients)) globals.key = value;
		// globals.adminPass = data.adminPass;
	},
	init: function() {
		// kick things off
		globals = this.settings;
		$(".footerCopyrightYear").append(globals.year);
		if(!!window.cordova) {
			globals.isApp = true;
			App.bindUIActions();
			// PhoneGap application
			document.addEventListener("deviceready", App.onDeviceReady, false); // Attendre que PhoneGap soit prêt
		}
		else {
			globals.isApp = false;
			App.bindUIActions();
			window.addEventListener('online', function(e) {
				// We are online or Back onLine !!
			}, false);
			window.addEventListener('offline', function(e) {
				// We are offline !!
				alert("La qualité du réseau ne permet pas de faire fonctionner l’application de manière optimale.", App.alertDismissed, 'SNS SOLUTIONS', 'OK');
			}, false);
		}
		fetch(datatablesFrench).then((res) => res.json()).then((data) => { // Fetching DataTables French locales
			globals.datatablesFrench = data;
			// console.log(globals.datatablesFrench);
		});
		/*
		fetch(langages)
		.then((res) => res.json())
		.then((data) => {
			globals.langages = data;
			// console.log(globals.langages);
		})
		.then(() => {
			this.bindUIActions();
			App.setLangages();
		});
		*/
	},
	onDeviceReady: function() {
		// PhoneGap est prêt
		document.addEventListener("resume", App.onResume, false);
		//document.addEventListener("pause", App.onPause, false);
		//navigator.splashscreen.hide();
		StatusBar.overlaysWebView(false);
		if(device.platform == 'iOS') {
			StatusBar.backgroundColorByHexString("#FFF");
			StatusBar.styleDefault(true); // Black text => styleLightContent = White text.
		}
		else StatusBar.backgroundColorByHexString("#009FE3"); // 'Android'
		globals.devicePlatform = device.platform;
		// prevent device from sleeping
		window.powermanagement.acquire();
		//if(device.platform == 'iOS') $('section').addClass('ios-section'); // Fixes the iOS backGround cover bug
		//globals.openPdf = cordova.plugins.disusered.open;
		document.addEventListener("offline", App.whenOffLine, false);
		document.addEventListener("online", App.backOnLine, false);
		// Replacing window.open by inAppBrowser
		window.open = cordova.InAppBrowser.open;
		if(navigator.notification) { // Override default HTML alert with native dialog
			window.alert = function (message) {
				navigator.notification.alert(
					message,    // message
					App.alertDismissed,       // callback
					"SNS SOLUTIONS", // title
					'OK'        // buttonName
				);
			};
		}
		/*
		cordova.plugins.notification.local.on("click", function (notification, state) {
			//alert(notification.id + " was clicked");
			if(notification.id=='1') $.mobile.pageContainer.pagecontainer("change", "#jobs_taker", { transition: "slide"} );
		}, this);
		// App.locateMe();
		cordova.plugins.notification.local.clearAll(function() {
			//alert("All notifications cleared");
		}, this);
		*/
	},
	alertDismissed: function() {
		// Do nothing !
	},
	/*
	// GESTION DES BOUTONS MATERIEL
	// Bouton retour
	onBackKeyDown: function() {
		navigator.notification.alert("Veuillez ne pas quitter BoursoConvois pendant la recheche de taxi disponibles", App.alertDismissed, 'BoursoConvois', 'OK');
	},
	// Bouton menu
	onMenuKeyDown: function() {
		// Do something
	},
	// Escaping...
	onPause: function() {
		if(idcourse!='') {
			stopCall();
			navigator.notification.alert("SI VOUS AVIEZ UNE COMMANDE EN COURS ELLE A ETE ANNULEE ! (CELA NE S'APPLIQUE PAS AUX RESERVATIONS)");
		}
	},
	*/
	onResume: function() {
		// setTimeout(function() {
		// 	navigator.notification.alert("onResume !", App.alertDismissed, 'SNS SOLUTIONS', 'OK');
		// }, 100);// iOS Quirk for interactive functions such as alert()
	},
	backOnLine: function() {
		this.checkAccountStatus();
	},
	whenOffLine: function() {
		// We are offline !!
		navigator.notification.alert("La qualité du réseau ne permet pas de faire fonctionner l’application de manière optimale.", App.alertDismissed, 'SNS SOLUTIONS', 'OK');
	},
	setLangages: function() {
		if(globals.myLang) $('#currentLangIcon').attr('src', 'img/assets/img/lang/'+globals.myLang+'.png');
		// Here every key in each langage should reflect to the data-lang property of the element it stranslates (<span data-lang="the_json_key"></span>)
		switch(globals.myLang) {
			case 'en':
				$.each(globals.langages.data.en, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'ge':
				$.each(globals.langages.data.ge, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'sp':
				$.each(globals.langages.data.sp, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'it':
				$.each(globals.langages.data.it, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'nl':
				$.each(globals.langages.data.nl, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			case 'pt':
				$.each(globals.langages.data.pt, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			break;
			default:
				$.each(globals.langages.data.fr, function(key, value) {
					// console.log(`${key} ${value}`);
					$('[data-lang="'+key+'"]').html(value);
				});
			}
	},
	changeLangage: function(chosenLangage) {
		globals.myLang = chosenLangage;
		$.localStorage.setItem('myLang', chosenLangage);
		// $('#currentLangIcon').attr('src', 'img/assets/img/lang/'+chosenLangage+'.png');
		this.setLangages();
	},
	checkAccountStatus: function () {
		$.post(globals.serverAddress, {id: $.localStorage.getItem('id'), login: $.localStorage.getItem('login'), pwd: $.localStorage.getItem('pwd'), req: 'checkAccountStatus'}, function(data){
			if(data.check!="GO") {
				alert("Il y a un problème avec votre compte, veuillez réessayer plus tard\r\nSi vous pensez qu'il s'agit d'une erreur, veuillez nous contacter.");
				App.logMeOut();
			}
			else {
				// Every things is ok so we refresh globals and we kick things off...
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mail', data.mail);
				$.localStorage.setItem('created', data.created);
				$.localStorage.setItem('lastmod', data.lastmod);
				$.localStorage.setItem('lastcon', data.lastcon);
				$.localStorage.setItem('clients', data.clients);
				$.sessionStorage.setItem('clientsInfos', data.clientsInfos);
				if(data.clientsInfos.length > 0) {
					data.clientsInfos.forEach(element => {
						for (const [key, value] of Object.entries(element)) console.log(key, value);
					});
				}
				// $.sessionStorage.setItem('adminPass', data.adminPass);
				// globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					App.changePage('homePage', 'loginPage');
					App.kickThingsOffOnceLoggedIn();
				}, 200);
			}
		}, "json").fail(function(){
			App.logMeOut();
			alert("Suite à un problème technique nous ne pouvons vérifier votre compte, veuillez réessayer plus tard\r\nSi l'erreur persiste, veuillez nous contacter.");
		});
	},
	logMeIn: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		const req = "login";
		query = query + "&req=" + req;
		$.post(globals.serverAddress, query, function(data){
			if(data.pass == "OK") {
				$.localStorage.setItem('pass', data.pass);
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mail', data.mail);
				$.localStorage.setItem('created', data.created);
				$.localStorage.setItem('lastmod', data.lastmod);
				$.localStorage.setItem('lastcon', data.lastcon);
				$.localStorage.setItem('clients', data.clients);
				$.sessionStorage.setItem('clientsInfos', data.clientsInfos);
				// data.clientsInfos.forEach(element => {for (const [key, value] of Object.entries(data.clients)) console.log(key, value);});
				// $.sessionStorage.setItem('adminPass', data.adminPass);
				// globals.adminPass = data.adminPass;
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
				setTimeout(function(){
					App.changePage('homePage', 'loginPage');
					App.kickThingsOffOnceLoggedIn();
				}, 600);
			}
			else {
				if(data.active==0) {
					alert("Ce compte a été désactivé !");
				}
				else {
					alert("Identifiant ou mot de passe erroné !");
					document.getElementById('forgottenPassWordCont').classList.remove('d-none');
				}
			}
		}, "json").always(function() {
			$(myFormDiv+' #sender').attr("disabled", false).html('<b><i class="fa fa-unlock me-1"></i>Connexion</b>');
			$(myFormDiv).closest('.row').css('opacity', 1);
		});
	},
	logMeOut: function () {
		$.localStorage.removeItem('pass');
		$.localStorage.removeItem('login');
		$.localStorage.removeItem('pwd');
		$.localStorage.removeItem('id');
		$.localStorage.removeItem('active');
		$.localStorage.removeItem('type');
		$.localStorage.removeItem('mail');
		$.localStorage.removeItem('created');
		$.localStorage.removeItem('lastmod');
		$.localStorage.removeItem('lastcon');
		$.localStorage.removeItem('clients');
		setTimeout(function(){
			document.location.reload();
			// App.changePage('loginPage', '');
		}, 1000);
	},
    showPassWord: function(thisBtn, event) {
        event.preventDefault(); // Prevents form submission
        const pwdInput = $(thisBtn).prev('input');
        const pwdInputType = $(pwdInput).attr('type');
        if(pwdInputType == 'password') {
			$(pwdInput).attr("type", "text");
			$(thisBtn).children("svg").removeClass("fa-eye").addClass("fa-eye-slash"); // FontAwesome JS replace <i> tag with <svg>
		}
        else {
			$(pwdInput).attr("type", "password");
			$(thisBtn).children("svg").removeClass("fa-eye-slash").addClass("fa-eye");
		}
    },
    forgottenPassWord: function(thisBtn, event) {
        event.preventDefault(); // Prevents form submission
		$(thisBtn).attr("disabled",true);
        const mail = $(thisBtn).closest('form').find('input[type=email]').val();
		if(this.validateMail(mail)) {
			$.post(globals.serverAddress, {'req': 'forgottenPassWord', 'login_u': mail}, function(data){
				if(data.ok=="ok") {
					$('#resetMail').val(mail);
					App.popModal('modalResetPassWord');
				}
				else alert("Une erreur c'est produite durant le processus de réinitialisation du mot de passe.\r\nSi le problème perssiste n'hésitez pas à nous contacter...");
			}, "json").always(function(){
				$(thisBtn).attr("disabled",false);
			});
		}
		else alert('Veuillez entrer un email valide !');
    },
    resetPassWord: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		$(myFormDiv).closest('.row').css('opacity', 0.6);
		let query = $(myFormDiv).serialize();
		query = query + "&req=resetPassWord";
		let returns = '';
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=='ok') {
				returns = '<div class="alert alert-success text-center" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Votre Compte a été modifiié avec succès, votre mot de passe est le suivant : '+data.pwd_u+'.<hr>Vous pouvez dès maintenant vous connecter.<hr>Un mail récapitulatif vous a été envoyé.</b></div>';
			}
			else if(data.error!='') {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Une erreur c\'est produite : '+data.error+'</b></div>';
			}
			else if(!data.isAuth) {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Le code de vérification est erroné.</b></div>';
			}
			else {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Une erreur non identifiée s\'est produite.</b></div>';
			}
		}, "json").always(function(){
			$(myFormDiv+' #sender').attr("disabled", false).html('Continuer').attr("onclick", "App.resetEnd(event);");
			$(myFormDiv+' #successfail').html(returns);
		}).fail(function(jqXHR, textStatus, errorThrown){
			alert('In resetPassWord AJAX Call FAILED !\n'+textStatus+'\n'+errorThrown, App.alertDismissed);
		});
    },
	resetEnd: (event) => {
        event.preventDefault(); // Prevents form submission
		$('#modalResetPassWord').find('.btn-close').trigger('click');
	},
	subContact: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "contact";
		query = query + "&req=" + req;
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok")
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Votre message a bien été envoyé.</b></div>';
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Votre message n\'a pas été envoyé.</b></div>';
		}, "json").always(function(){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-paper-plane me-1"></i>Envoyer');
			$(myFormDiv+' #successfail').html(returns);
		});
	},
	subscribeApp: function (myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		let query = $(myFormDiv).serialize();
		const req = "subscribeApp";
		const step = ($(myFormDiv+" #token_u").val()!='') ? 2 : 1;
		// const step = $("#subscribeStep").val();
		query = query + "&req=" + req + "&subscribeStep=" + step;
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				if(step==1) {
					returns = '<div class="alert alert-success text-center" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Afin de vérifier votre adresse mail, nous vous avons envoyé un code de première connexion.<hr>Si vous pensez ne pas l\'avoir reçu, veuillez vérifier vos spams.<hr>Une fois reçu veuillez le renseigner ci-dessus</b></div>';
					$(myFormDiv+' #subscribeStep').val(2);
					// $(myFormDiv+' #login_u').closest('.form-group').addClass('d-none');
					$(myFormDiv+' #token_u').attr('required', true).attr('pattern', '[0-9]{6}$').closest('.form-group').removeClass('d-none');
				}
				else { // So step is 2
					returns = '<div class="alert alert-success text-center" role="alert"><b><i class="fa fa-2x fa-check-circle me-1"></i>Compte créé avec succès, votre mot de passe est le suivant : '+data.pwd_u+'.<hr>Vous pouvez dès maintenant vous connecter.<hr>Un mail récapitulatif vous a été envoyé.</b></div>';
					$('#loginEmail').val($(myFormDiv+' #login_u').val());
					$('#loginPassword').val(data.pwd_u);
					setTimeout(function() { // Auto submit loginForm & close current modal
						App.logMeIn('#loginForm');
						$('#modalSubscribe').find('.btn-close').trigger('click');
						// let myModal = new bootstrap.Modal('#modalSubscribe');
						// myModal.hide();
					}, 3600);
				}
			}
			else if(data.error!='') {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Une erreur c\'est produite : '+data.error+'</b></div>';
			}
			else if(step==2) {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Le code de première connexion est erroné.</b></div>';
			}
			else {
				returns = '<div class="alert alert-danger text-center" role="alert"><b><i class="fa fa-2x fa-times-circle me-1"></i>Votre adresse email n\'a pas été reconnue par notre système.<hr>Si vous êtes client, veuillez nous contacter afin de corriger cela.</b></div>';
			}
		}, "json").always(function(){
			$(myFormDiv+' #sender').attr("disabled", false).html('Continuer');
			$(myFormDiv+' #successfail').html(returns);
		}).fail(function(jqXHR, textStatus, errorThrown){
			alert('In subscribeApp AJAX Call FAILED !\n'+textStatus+'\n'+errorThrown, App.alertDismissed);
		});
	},
	addWasValidatedClass: function (myFormDiv) {
		$(myFormDiv).addClass('was-validated');
		var wrongFields = "Les champs suivants sont mal renseignés : \n";
		var wrongPop = false;
		$(myFormDiv).find(':invalid').each(function () {
			wrongPop = true;
			$(this).closest('.form-group').find('label').not('.notValidatedClass').each(function () {
				const fieldLabel = $(this).text();
				if(fieldLabel!='') wrongFields += fieldLabel+"\n";
			});
		});
		if(wrongPop) {
			alert(wrongFields);
		}
		return !wrongPop;
	},
	clearFormFields: function(myFormDiv, event) {
		// if(myFormDiv=='#editPicturesAddressForm') event.preventDefault(); // prevents form submission when button is inside it !
		$(myFormDiv).find("input[type=text], input[type=tel], input[type=email], input[type=file], input[type=password], textarea, select").val('');
		$(myFormDiv).find("input[type=range], input[type=number]").val(0); // Making sure Numbers & Ranges inputs are reset to 0
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
	},
	closeModal: function(modalDiv) {
		const myModalId = (modalDiv.includes('#')) ? modalDiv : '#'+modalDiv
		const myModal = bootstrap.Modal.getInstance(myModalId)
		myModal.hide()
	},
	safeJsonParse: function(input) {
		try {
			return JSON.parse(input);
		} catch (e) {
			return undefined;
		}
	},
	urlParam: function(name, url){
		// Get parameters from an URL
		var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(url);
		//For current URL
		//var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results==null){
			return null;
		}
		else{
			return results[1] || 0;
		}
	},
	bindUIActions: function() {
		// Connected or not
		if(globals.pass == "OK") {
			App.checkAccountStatus();
			// App.kickThingsOffOnceLoggedIn();
		}
		else App.changePage('loginPage', '');
		// Is it Mobile device
		if(/Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) || window.innerWidth<992) globals.isMobile = true;
		if(globals.isMobile) {
			$('#myTab').removeClass('nav-tabs').addClass('nav-pills');
		}
		$('.expends').on("click", function () {
			$(this).next('div').slideToggle('slow');
			//$.mobile.silentScroll($(this).next('div').offset().top);
		});
		// Managing navigation so that we bindUIActions on the right page...
		let url = window.location.pathname;
		globals.myPage = url.substring(url.lastIndexOf('/')+1);
		$.sessionStorage.setItem('myPage', globals.myPage);
		// Smooth Scroll to div...
		App.refreshSmoothScroll();
		// primary navigation slide-in effect in landscape mode
		const MQL = 992;
		let landscapeDetect = window.matchMedia("(orientation: landscape)");
		let landscape = false;
		landscapeDetect.addEventListener("change", function(e) {
			console.log("landscape: "+e.matches);
			if(e.matches) {
				landscape = true; // Landscape mode
			} else {
				landscape = false; // Portrait mode
			}
		});
		$(window).on('scroll', {
			previousTop: 0
		}, function() {
			if(landscape) {
				var currentTop = $(window).scrollTop();
				$('#mainNav').removeClass('is-visible').removeClass('is-fixed'); // In case We switched from wide screen to Mobile.
				// $('.dropdown-menu').addClass('show'); // dropdown-menu bugs when on mobile devices (inside mobile collapse)
				if(currentTop < this.previousTop) {
					$('#mainNav').fadeIn("slow"); // if scrolling up...
				}
				else if (currentTop > this.previousTop) {
					$('#mainNav').fadeOut("slow"); // if scrolling down...
				}
				this.previousTop = currentTop;
			}
		});
		$('section').on("click", function () {
			if(window.innerWidth < MQL) {
				$('#mainNav .navbar-collapse').removeClass('show');
				// $('.navbar').slideToggle("slow");
			}
		});
		$('.nav-item').on("click", function () {
			if(window.innerWidth < MQL) {
				$('#mainNav .navbar-collapse').removeClass('show');
				// $('.navbar').slideToggle("slow");
			}
		});
		document.addEventListener("scroll", function (event) {
			if (App.getDocHeight() == App.getScrollXY()[1] + window.innerHeight) {
				//$('.go-up-fixed').fadeOut('slow');
				globals.bottomScrolled=true;
			}
			else {
				globals.bottomScrolled=false;
				if(App.getScrollXY()[1] == 0) {
					$('.go-up-fixed').fadeOut('slow');
				}
				else
					$('.go-up-fixed').fadeIn('slow');
			}
		});
	},
	kickThingsOffOnceLoggedIn: function() {
		// Display .multipleOnly & .singleOnly Objects and wether they are displayed block or flex.
		switch (globals.type) {
			case 'multiple':
				$(".multipleOnly").show();
				$(".multipleOnlyFlex").css('display', 'flex');
				$(".multipleHide").hide();
			break;
			case 'single':
				$(".singleOnly").show();
				$(".singleOnlyFlex").css('display', 'flex');
				$(".singleHide").hide();
				$('.allUsersRemove').remove();
				$('#interventionListContract').removeClass('d-lg-none');
			break;
		}
		$('.my-account-mail').html(globals.login);
		// $('#modalContact #from_name').val(globals.name+' '+globals.firstname);
		$('#modalContact #from_email').val(globals.login);
		// $('#modalContact #from_phone').val(globals.phone);
		// $('#modalContact #from_company').val(globals.company);
		let clientsBoxOptions = (globals.type=='multiple') ? '<option value="">TOUS SITES</option>' : '';
		let clientsInterBoxOptions = '';
		let maintenance = 'NC';
		if(globals.clientsInfos.length > 0) {
			globals.clientsInfos.forEach((item, index) => {
				if(index==0) globals.defaultIdClient = item.idClient;
				clientsBoxOptions += `<option value="${item.idClient}" data-contract="${item.Maintenance}">${item.NomClient}</option>`;
				clientsInterBoxOptions += `<option value="${item.idClient}" data-mails="${item.EmailContactClient}">${item.NomClient}</option>`;
				// for (const [key, value] of Object.entries(item)) console.warn(key + ': ' + value);
				maintenance = item.Maintenance;
			});
			if(globals.type=='single') $('#interventionListContract').html(`<i class="fa fa-signature me-1"></i>Contrat de maintenance ${maintenance}`);
		}
		$('[data-clients-boxes]').html(clientsBoxOptions);
		$('#IdClientInter').html(clientsInterBoxOptions).trigger('change');
		this.buildMyDropzone('#addInterventionForm', App.addIntervention, 'addIntervention', 'addIntervention', true);
		this.buildMyDropzone('#modInterventionForm', App.modIntervention, 'modIntervention', 'modIntervention', true);
		window.addEventListener("resize", e => {
			globals.myLineChart.resize() // Refresh Chart when window is resized
		});
		window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
			const portrait = e.matches;
			globals.myLineChart.resize()
			// if (portrait) {// do something
			// } else { // do something else
			// }
		});
	},
	getFormsSelectBoxes: async function(formsDivArray, myController, inputsArray) {
		let result;
		// Using try to get error handling, in order to have just the promise : const result = await $.post(...) would have done it.
		try {
			result = $.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, clientId: globals.auto_c, req: myController, action: 'getFormsSelectBoxes'}, function(data){
				if(formsDivArray.length>0 && inputsArray.length>0) {
					formsDivArray.forEach((formDiv, indexDiv) => {
						inputsArray.forEach((input, index) => {
							$(formDiv+" #"+input).html(data[input]); // Can't use data.input here
							// console.log(formDiv+" #"+input+" | "+data[input]);
						});
					});
				}
			}, "json").always(function() {
			});
			return result;
		} catch (error) {
			console.error(error);
		}
	},
	changePage: function(pageToShow, previousPage) {
		if (previousPage.indexOf('Page') === -1) {
			previousPage = $('.fullpage:visible').attr('id');
		}
		if (pageToShow != previousPage) {
			$('.fullpage').not('#' + pageToShow).fadeOut();
			$('#' + pageToShow).fadeIn();
		}
		// Modifying return link...
		$('#returnLink').attr('onclick', '').attr('onclick', 'App.changePage(\'' + previousPage + '\', \'' + pageToShow + '\')');
		globals.myPage = pageToShow;
		$.sessionStorage.setItem('myPage', pageToShow);
	},
	openSomeUrl: function(url) {
		if(globals.isApp) { // First one opens url in inAppBrowser the second in the system's browser like iOS => Safari
			window.open(url,'_blank','location=false,enableViewportScale=yes,scrollbars=yes,closebuttoncaption=Fermer,toolbarcolor=#009FE3,closebuttoncolor=#000000');
			// window.open(url, '_system', 'location=false,enableViewportScale=yes,scrollbars=yes,closebuttoncaption=Fermer');
		}
		else {
			console.log('In openSomeUrl WebApp Section...')
			const a = $("<a>").attr("href", url).attr("target", "_blank").appendTo("footer");
			a[0].click();
			a.remove();
		}
	},
	openSnsDocs: function(getThisUrl) {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, myProtectedUrl: getThisUrl, req: 'getThisUrl'}, function(data){
			const pageContentUrl = 'data:text/html;base64,' + window.btoa(data.pageContent);
			openSomeUrl(pageContentUrl);
		}, "json").always(function() {
		});
	},
	popModal: function(myModalId) {
		const myModalObject = document.getElementById(myModalId);
		if (myModalObject) {
			let myModal = new bootstrap.Modal(myModalObject);
			myModal.show();
		}
		else {
			setTimeout(function() { // Little timer to let the system charge page if needed
				App.popModal(myModalId);
			}, 1000);
		}
	},
	refreshSmoothScroll: function() {
		// Smooth Scroll to div on anchors click...
		$('a[href*="#"]').not('a.noscroll').on('click', function (event) {
			event.preventDefault();
			let offset = 0;
			const target = this.hash;
			if($(this).data('offset') != undefined) offset = $(this).data('offset'); // if set data-offset="pixels"
			if($(target).length) {
				$('html, body').stop().animate({
					'scrollTop': $(target).offset().top - offset
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
			else { // Scrolls to top...
				$('html, body').stop().animate({
					'scrollTop': 0
				}, 900, 'swing', function() {
					window.location.hash = target;
				});
			}
		});
	},
	smoothScrollTo: function(target, offset) {
		// Smooth Scroll to div...
        if($(target).length) {
            $('html, body').stop().animate({
                'scrollTop': $(target).offset().top - offset
            }, 1600, 'swing', function() {
                // window.location.hash = target;
            });
        }
	},
	number_format: function(number, decimals, dec_point, thousands_sep) {
		// *     example: App.number_format(1234.56, 2, ',', ' ');
		// *     return: '1 234,56'
		number = (number + "").replace(",", "").replace(" ", "");
		var n = !isFinite(+number) ? 0 : +number,
			prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
			sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
			dec = typeof dec_point === "undefined" ? "." : dec_point,
			s = "",
			toFixedFix = function(n, prec) {
				var k = Math.pow(10, prec);
				return "" + Math.round(n * k) / k;
			};
		// Fix for IE parseFloat(0.55).toFixed(0) = 0;
		s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
		if (s[0].length > 3) {
			s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
		}
		if ((s[1] || "").length < prec) {
			s[1] = s[1] || "";
			s[1] += new Array(prec - s[1].length + 1).join("0");
		}
		return s.join(dec);
	},
	buildDataTables: function(myDiv, pageLength = 25, colReorder = true, orderCol = 0, orderWay = 'desc', expTitle = '', filterColumsText = [], filterColumsSelect = [], myFilterField, myFilterCondition, myFilter, pdfExtraExportOptions, fixedHeader) {
		// Like : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], '');
		// Or : App.buildDataTables('#dataTable', 25, true, 4, 'desc', 'Titre', [0,1,2,3,4,5,6], [7,8], 'Centre', '=', myFilter, [0,1,2,3,4,5,6], true);
		fixedHeader = (typeof fixedHeader !== 'undefined') ? fixedHeader : true;
		let fixedFooter = false;
		let headerOffset = 60;
		if(globals.isMobile) fixedHeader = false;
		const dom = (globals.type=='user') ? '<"row"<"col-12 col-md-6 d-flex align-items-center py-1"l><"col-12 col-md-6 d-flex align-items-center justify-content-end py-1"f>rt<"col-12 col-md-6 py-1"i><"col-12 col-md-6 py-1"p>>' : '<"row"<"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-start py-1"l><"col-12 col-md-4 d-flex align-items-center justify-content-center py-1"f><"col-12 col-md-4 d-flex align-items-center justify-content-center justify-content-md-end py-1"B>>rt<"row"<"col-12 col-md-6 py-1"i><"col-12 col-md-6 py-1"p>>';
		// Add row class to dataTable wrapper => Usefull to correcty display buttons using the dom option => Done in css directly on .dataTables_wrapper
		// $(myDiv+'_wrapper').addClass('row');
		// Add filter row in header...
		// $(myDiv+' thead tr').clone(true).addClass('filters').appendTo(myDiv+' thead');
		const pdfExtra = (typeof pdfExtraExportOptions !== 'undefined') ? pdfExtraExportOptions : '';
		const baseFiltering = (typeof myFilter !== 'undefined') ? myFilter : '';
		let myTable;
		let dataTableOptions = {
			destroy: true,
			dom: dom,
			language: globals.datatablesFrench,
			// language: {
			//	url: 'https://cdn.datatables.net/plug-ins/1.11.3/i18n/fr_fr.json'
			// },
			pageLength: pageLength,
			colReorder: colReorder,
			fixedHeader: {header: fixedHeader, footer: fixedFooter, headerOffset: headerOffset},
			order: [], // disable default sorting order wi'll apply ordering conditionaly below
			buttons: [
				{text:'<li class="fa fa-2x fa-copy"></li>', title: expTitle, extend:'copy'},
				{text:'<li class="fa fa-2x fa-file-excel"></li>', title: expTitle, extend:'excel'},
				{text:'<li class="fa fa-2x fa-file-pdf"></li>', title: expTitle, exportOptions: {stripNewlines: false, columns: pdfExtra}, orientation: 'landscape', pageSize: 'LEGAL', extend:'pdf'},
				{text:'<li class="fa fa-2x fa-print"></li>', title: expTitle, exportOptions: {stripHtml: false, stripNewlines: false}, extend:'print'}
			],
			initComplete: function () {
				if(filterColumsText.length>0 || filterColumsSelect.length>0) {
					$(myDiv+' thead tr').clone(false).addClass('filters').appendTo(myDiv+' thead');
					$(myDiv+' tr.filters .filter-false').empty().removeClass('sorting');
				}
				if(filterColumsText.length>0) {
					this.api().columns(filterColumsText).every( function () {
						let column = this;
						let columnIndex = $(column.header()).index()
						const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeAttr('data-dt-column');
						// const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeClass(['sorting', 'sorting_asc', 'sorting_desc']);
						let input = $('<input class="form-control form-control-sm table-filter" type="text" placeholder="Rechercher" data-filter-column="'+columnIndex+'" />')
						.appendTo( $(cell) )
						// .appendTo( $(column.header()) )
						.off('keyup change')
						.on('keyup change', function (e) {
							// e.stopPropagation();
							let val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);
							let regexr = '({search})';
							column.search(val != '' ? regexr.replace('{search}', '(((' + val + ')))') : '', val != '', val == '').draw();
						});
					});
				}
				if(filterColumsSelect.length>0) {
					this.api().columns(filterColumsSelect).every( function () {
						let column = this;
						let columnIndex = $(column.header()).index()
						let filterDataArray = [];
						const cell = $(myDiv+' tr.filters th').eq(columnIndex).empty().removeAttr('data-dt-column');
						let select = $('<select class="form-select form-select-sm table-filter" data-filter-column="'+columnIndex+'"><option value=""></option></select>')
						.appendTo( $(cell) )
						// .appendTo( $(column.header()) )
							.on( 'change', function () {
								let val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
								column
									.search( val ? '^'+val+'$' : '', true, false )
									.draw();
							});
						column.data().unique().sort().each( function ( d, j ) {
							let filterData = d;
							if(d.indexOf('</') !== -1) { // In case there is html tags we strip those...
								filterData = ($(d).length) ? $(d).text() : d;
								// console.warn(d+' - '+$(d));
							}
							// select.append( '<option value="'+filterData+'">'+filterData+'</option>' );
							if(filterData!='') filterDataArray.push(filterData);
						});
						// In case there was html tags we need to sort the data again
						filterDataArray.sort();
						filterDataArray.forEach(element => {
							select.append( '<option value="'+element+'">'+element+'</option>' );
						});
					});
				}
			}
		}
		if(baseFiltering.length>0) {
			let tabFilter = new Array(myFilter);
			dataTableOptions = {
				searchBuilder: {
					preDefined: {
						criteria: [
							{
								data: myFilterField,
								condition: myFilterCondition,
								value: tabFilter
							}
						]
					}
				},
				...dataTableOptions
			}
		}
		myTable = $(myDiv).DataTable(dataTableOptions);
		// Conditionaly ordering now
		if(orderWay) myTable.order([ orderCol, orderWay ]).draw(); // Type comparison here is important because of col zero
		// myTable.fixedHeader.adjust();
		// if(globals.isMobile) myTable.fixedHeader.disable();
	},
	resetFilters: function() {
		$('.table-filter').each(function() {
			$(this).val('');
		}).trigger('change');
		$('.dataTables_filter input[type=search]').each(function() {
			$(this).val('').trigger('keyup');
		});
	},
	downloadFiles: function (link, name, thisBtn, event) {
		event.preventDefault();
		$(thisBtn).attr("disabled", true);
		const a = $("<a>").attr("href", link).attr("download", name).appendTo("footer");
		a[0].click();
		a.remove();
		$(thisBtn).attr("disabled", false);
	},
	setInterventionsListPage: function(action, interventionListName, refresh) {
		$('header .btn-icon').removeClass('active');
		$('#'+action).addClass('active');
		if(action!='getInterventionsListCCY') {
			$('#interventionsTableCard').addClass('d-none d-lg-block');
			$('#interventionsListMobileCont').removeClass('d-none').addClass('d-block d-lg-none');
		}
		else {
			$('#interventionsTableCard').removeClass('d-none d-lg-block');
			$('#interventionsListMobileCont').removeClass('d-block d-lg-none').addClass('d-none');
		}
		$('.dataTable').DataTable().destroy(true); // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, clients: globals.clients, pwd: globals.pwd, ap: globals.adminPass, req: action}, function(data){
			$("#interventionsListCont").html(data.snippet);
			$('#interventionListCount').html('<b><i class="fa fa-chevron-circle-right mx-2"></i>'+data.howManyTickets+'</b>');
			if(action!='getInterventionsListCCY') $("#interventionsListMobileCont").html(data.snippetPhone);
		}, "json").done(function(data) {
			if(globals.type=='single')
				App.buildDataTables('#dataTableInterventionsList', 20, false, 0, 'desc', 'SNS-APP Liste des Interventions', [0,1,3], [2,4,5], '', '', '', [0,1,2,3,4,5], true);
			else
				App.buildDataTables('#dataTableInterventionsList', 20, false, 0, 'desc', 'SNS-APP Liste des Interventions', [0,1,4], [2,3,5,6], '', '', '', [0,1,2,3,4,5,6], true);
		}).always(function(){
			// App.getFormsSelectBoxes(['#modClientForm', '#addClientForm'], 'interventionsController', ['AgenceGestionClient','AgenceFacturationClient']);
		});
		if(!refresh) {
			$('#interventionListName').html('<i class="fa fa-tasks"></i><b>&nbsp;'+interventionListName+'</b>');
			$('#interventionListSubTitle').html('<i class="fa fa-tasks"></i><b>&nbsp;Liste des '+interventionListName+'</b>');
			$('#interventionsListRefresher').attr('onclick', 'App.setInterventionsListPage(\''+action+'\', \''+interventionListName+'\', true)');
			// this.getFormsSelectBoxes(['#addInterventionForm'], 'interventionsController', ['IdMere','ThemeClient','IdClientInter','Intervenant']);
			// this.buildMyDropzone('#addInterventionForm', App.addIntervention, 'interventionsController', 'addIntervention', true);
			// this.buildMyDropzone('#modInterventionForm', App.modIntervention, 'interventionsController', 'modIntervention', true);
			$('#addInterventionForm #Probleme').val(globals.defaultProblem);
		}
		/*
		const refreshAction = (globals.interventionsListsToRefresh.findIndex((element) => element==action) !== -1) ? true : false;
		if(globals.interventionsRefresh && refreshAction) {
			// We trigger click because if button is not here it won't do anything !
			globals.interventionsRefresher = setInterval(function(){$('#interventionsListRefresher').trigger('click');}, globals.interventionsRefresherTimer);
			console.warn('interventionsListRefresher: '+action+' | Id: '+globals.interventionsRefresher);
			globals.interventionsRefresh = false;
		}
		else if(!refreshAction) {
			clearInterval(globals.interventionsRefresher);
			if(globals.interventionsRefresher!=0) globals.interventionsRefresh = true;
			$('#interventionAutoRefresher').hide();
		}
		if(globals.interventionsRefresher==0) { // Keeps button's state between page changes => this one is set to 0 when interval is cleared.
			$('#interventionAutoRefresher').removeClass('btn-outline-danger').addClass('btn-success').attr('onclick', 'App.startInterventionRefresher(this);').attr('title', 'Activer l\'actualisation automatique');
			console.log('changed state !');
		}
		console.log('interventionsRefresh : '+globals.interventionsRefresh);
		*/
	},
	setInterventionPage: function(idInter) {
		// const generateSelectBoxes = await this.getFormsSelectBoxes([myFormDiv], 'interventionsController', ['IdMere','ThemeClient','IdClientInter','Intervenant']);
		let titleInter = '';
		$('#headerInterInfos').html("Intervention #"+idInter);
		$('#headerInter').html("#"+idInter);
		$('#interventionPageRefresher').attr('onclick', `App.setInterventionPage(${idInter});`);
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, idInter: idInter, req: 'fillModIntervention'}, function(data){
			if(data.ok=="ok") {
				$('#interventionCont').html(data.snippet);
				titleInter = '<i class="fa fa-arrow-circle-right"></i> Créée par '+data.interventions.QuiCreeIntervention;
				if(data.interventions.QuiModifieIntervention!='') titleInter+=', modifiée par '+data.interventions.QuiModifieIntervention;
				// titleInter += ' (IP:'+data.interventions.IPenregistrement+')';
				const nowDate = new Date();
				// titleInter += ' Ouverture à '+App.makeNiceTime24(nowInMinutes, 0);
				$('#titleInter').html(titleInter);
				// $('#interventionFilesCont').html(data.uploads);
			}
			else alert("Suite à un problème technique, je n'ai pas retrouvé cette intervention !");
		}, "json").always(function(){
		});
		/*
		const litepickerDateRappel = document.getElementById('Date_Rappel');
		if (litepickerDateRappel) {
			new Litepicker({
				element: litepickerDateRappel,
				format: 'DD/MM/YYYY',
				lang: 'fr-FR',
			});
		}
		const litepickerDateRdv = document.getElementById('Date_RDV');
		if (litepickerDateRdv) {
			new Litepicker({
				element: litepickerDateRdv,
				format: 'DD/MM/YYYY',
				lang: 'fr-FR',
			});
		}
		*/
		// this.buildMyDropzone('#modInterventionPageForm', App.modIntervention, 'interventionsController', 'modIntervention', false);
	},
	interventionsFilterClient: function(thisInput) {
		const idClient = thisInput.value;
		if(idClient!='') {
			$('[data-filter=1]').each(function(){
				if($(this).data('filter-client')!=idClient) $(this).fadeOut();
				else $(this).fadeIn();
			});
			const maintenance = $(thisInput).find(':selected').data('contract');
			$('#interventionListContract').html(`<i class="fa fa-signature me-1"></i>Contrat de maintenance ${maintenance}`);
			globals.currentInterventionsFilterClient = idClient;
		}
		else {
			$('[data-filter=1]').fadeIn();
			$('#interventionListContract').html(`<i class="fa fa-signature me-1"></i>Contrat de maintenance`);
		}
	},
	interventionsFilterSearch: function(thisInput) {
		// data-filter=1 data-filter-id="" data-filter-client="" data-filter-status="" data-filter-state="" data-filter-object="" data-filter-date=""
		const search = (thisInput) ? thisInput.value.toLowerCase() : '';
		if(search!='') {
			$('div[data-filter=1]').each(function(){
				if(globals.currentInterventionsFilterClient!='') { // Must keep client filter alive
					if($(this).data('filter-client')==globals.currentInterventionsFilterClient && ($(this).attr('data-filter-id').indexOf(search)!==-1 || $(this).attr('data-filter-status').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-state').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-object').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-date').indexOf(search)!==-1)) $(this).fadeIn('slow');
					else $(this).fadeOut('slow');
				}
				else {
					if($(this).attr('data-filter-id').indexOf(search)!==-1 || $(this).attr('data-filter-status').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-state').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-object').toLowerCase().indexOf(search)!==-1 || $(this).attr('data-filter-date').indexOf(search)!==-1) $(this).fadeIn('slow');
					else $(this).fadeOut('slow');
				}
			});
		}
		else $('[data-filter=1]').fadeIn('slow');
	},
	emptyInterventionSearchField: function(thisBtn) {
		$(thisBtn).closest('.form-group').find('input').val('');
		this.interventionsFilterSearch()
	},
	dataFilterField: function(thisInput) {
		const filter = thisInput.value;
		const searchInput = document.querySelector('[data-search-input]')
		const search = (searchInput) ? this.normalizeSearchFields(searchInput.value) : '';
		if(filter!='' && search!='') {
			$('[data-filter=1]').each(function(){
				const filterText = App.normalizeSearchFields($(this).data('filter-'+filter))
				if(filterText.includes(search)) $(this).fadeIn('slow');
				else $(this).fadeOut('slow');
			});
		}
		else $('[data-filter=1]').fadeIn('slow');
	},
	dataFilterSearch: function(thisInput) {
		const dataFiltersContainer = document.querySelector('[data-filters]')
		const filters = dataFiltersContainer.getAttribute('data-filters').split(',') // Get filters from results container
		const search = (thisInput) ? this.normalizeSearchFields(thisInput.value) : '';
		const filterInput = document.querySelector('[data-filter-input]')
		const filter = (filterInput) ? filterInput.value : '';
		if(search!='') {
			$('div[data-filter=1]').each(function(){
				const _thisResult = $(this)
				// console.log(_thisResult)
				if(filter!='') { // Searching in a single data-filter
					const filterText = App.normalizeSearchFields(_thisResult.data('filter-'+filter))
					if(filterText.includes(search)) _thisResult.fadeIn('slow');
					else _thisResult.fadeOut('slow');
				}
				else { // Searching in all filters
					let found = false
					filters.forEach((item) => {
						const filterText = App.normalizeSearchFields(_thisResult.attr('data-filter-'+item))
						if(filterText.includes(search)) {
							found = true;
						}
					})
					if(found) _thisResult.fadeIn('slow');
					else _thisResult.fadeOut('slow');
				}
			});
		}
		else $('[data-filter=1]').fadeIn('slow');
	},
	normalizeSearchFields: function(myString) {
		// const objectAlphabetAll = {'Š':'S', 'š':'s', 'Đ':'Dj', 'đ':'dj', 'Ž':'Z', 'ž':'z', 'Č':'C', 'č':'c', 'Ć':'C', 'ć':'c', 'À':'A', 'Á':'A', 'Â':'A', 'Ã':'A', 'Ä':'A', 'Å':'A', 'Æ':'A', 'Ç':'C', 'È':'E', 'É':'E', 'Ê':'E', 'Ë':'E', 'Ì':'I', 'Í':'I', 'Î':'I', 'Ï':'I', 'Ñ':'N', 'Ò':'O', 'Ó':'O', 'Ô':'O', 'Õ':'O', 'Ö':'O', 'Ø':'O', 'Ù':'U', 'Ú':'U', 'Û':'U', 'Ü':'U', 'Ý':'Y', 'Þ':'B', 'ß':'Ss', 'à':'a', 'á':'a', 'â':'a', 'ã':'a', 'ä':'a', 'å':'a', 'æ':'a', 'ç':'c', 'è':'e', 'é':'e', 'ê':'e', 'ë':'e', 'ì':'i', 'í':'i', 'î':'i', 'ï':'i', 'ð':'o', 'ñ':'n', 'ò':'o', 'ó':'o', 'ô':'o', 'õ':'o', 'ö':'o', 'ø':'o', 'ù':'u', 'ú':'u', 'û':'u', 'ý':'y', 'ý':'y', 'þ':'b', 'ÿ':'y', 'Ŕ':'R', 'ŕ':'r'}
		const objectAlphabetFr = {'à':'a', 'á':'a', 'â':'a', 'ä':'a', 'ç':'c', 'è':'e', 'é':'e', 'ê':'e', 'ë':'e', 'ì':'i', 'í':'i', 'î':'i', 'ï':'i', 'ò':'o', 'ó':'o', 'ô':'o', 'ö':'o', 'ù':'u', 'ú':'u', 'û':'u'}
		myString = myString.toString().toLowerCase()
		for (const [replaced, replacement] of Object.entries(objectAlphabetFr)) {
			myString = myString.replaceAll(replaced, replacement)
		}
		return myString
	},
	emptySearchField: function(thisBtn) {
		$(thisBtn).closest('.form-group').find('input').val('');
		this.dataFilterSearch()
	},
	setInterventionDefaultProblem: function(thisBtn, event) {
		event.preventDefault();
		const myForm = $(thisBtn).closest('form');
		$(myForm).find('#Probleme').val(globals.defaultProblem);
	},
	getClientsMails: function(thisInput) {
		const mails = $(thisInput).find(':selected').data('mails');
		const formId = '#'+$(thisInput).closest('form').attr('id');
		// const idClient = thisInput.value;
		$(formId+" #Mail_Client").val(mails);
	},
	clearFormInters: function(myFormDiv) {
		$(myFormDiv).find("input, textarea, select").val('');
		$(myFormDiv).find("input[type=checkbox]").prop("checked", false);
		$(myFormDiv).find("input[type=radio]").prop("checked", false);
		$(myFormDiv).removeClass('was-validated');
		$(myFormDiv+' #successfail').empty();
		$(myFormDiv+' #successfailTop').empty();
	},
	addIntervention: function(myFormDiv, fromModal) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		// let query = $(myFormDiv).serialize();
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		// request = request + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&name=" + globals.name + "&firstname=" + globals.firstname + "&req=interventionsController&action=addIntervention";
		$(myFormDiv+' input[type=checkbox]').each(function () { // Setting checkboxes values
			$(this).val(Number(this.checked));
		});
		$(myFormDiv+' input[type=checkbox]').each(function () { // Set radios values
			$(this).val(Number(this.checked));
		});
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "addIntervention"); // req is included in form data here
		request.append("action", "addIntervention");
		request.append("id", globals.id);
		request.append("type", globals.type);
		request.append("pwd", globals.pwd);
		request.append("name", "Client Application");
		request.append("firstname", globals.login);
		// We add disabled inputs vars manually to the POST...
		// request.append("project",  $(myFormDiv+' #project').val());
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>L\'intervention a bien été ajoutée.</b></div>';
				if(fromModal===true) {
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
						// Set #Probleme field back to default and empty out assignments areas...
						$(myFormDiv+' #Probleme').val(globals.defaultProblem);
						$('#interventionsListRefresher').trigger('click'); // Refresh interventionsListPage
					}, 3600);
				}
				else {
					App.clearFormFields(myFormDiv+' #addActionForm');
					$(myFormDiv+' #addActionForm').slideUp();
				}
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>L\'intervention n\'a pas été ajoutée, suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-plus-circle me-1"></i>Enregistrer');
			$(myFormDiv+' #successfail').html(returns);
			$(myFormDiv+' #successfailTop').html(returns);
		});
	},
	modInterventionClient: function(idInter) {
		$('#modInterventionForm #idInter').val(idInter);
		let myModal = new bootstrap.Modal(document.getElementById("modInterventionModal"));
		myModal.show();
	},
	modIntervention: function(myFormDiv, fromModal) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		// let query = $(myFormDiv).serialize();
		// Serializing checkBoxes first for regular checkBoxes, second for Bootstrap's customs (assuming id=name)...
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.name+"="+this.checked;}).get().join("&");
		// let checkBoxes=$(myFormDiv+" input[type='checkbox']").map(function(){return this.id+"="+this.checked;}).get().join("&");
		// request = request + "&" + checkBoxes + "&id=" + globals.id + "&pwd=" + globals.pwd + "&req=" + req;
		// query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&name=" + globals.name + "&firstname=" + globals.firstname + "&req=interventionsController&action=modIntervention";
		$(myFormDiv+' input[type=checkbox]').each(function () { // Setting checkboxes values
			$(this).val(Number(this.checked));
		});
		$(myFormDiv+' input[type=checkbox]').each(function () { // Set radios values
			$(this).val(Number(this.checked));
		});
		let request = new FormData($(myFormDiv)[0]);
		request.append("req", "modIntervention"); // req is included in form data here
		request.append("action", "modIntervention");
		request.append("id", globals.id);
		request.append("type", globals.type);
		request.append("pwd", globals.pwd);
		request.append("name", "Client Application");
		request.append("firstname", globals.login);
		// We add disabled inputs vars manually to the POST...
		// request.append("project",  $(myFormDiv+' #project').val());
		let returns = "";
		$.ajax({
			url: globals.serverAddress,
			type: 'POST',
			data: request,
			dataType: "json",
			cache: false,
			contentType: false,
			processData: false
		}).done(function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>L\'intervention a bien été mise à jour.</b></div>';
				if(fromModal===true) {
					// App.setInterventionsListPage('getInterventionsList', 'Interventions en cours', true);
					setTimeout(function(){
						$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
						App.clearFormFields(myFormDiv);
						$('#interventionPageRefresher').trigger('click'); // Refresh interventionPage
					}, 3600);
				}
			}
			else returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>L\'intervention n\'a pas été modifiée suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' #successfail').html(returns);
		});
	},
	setStatsPage: async function() {
		// await this.getFormsSelectBoxes(['#dataFilterStatsBox'], 'interventionsController', ['IdClientInter']);
		document.querySelector('[data-filter-stats-client-input]').value = globals.defaultIdClient
		this.getClientStatsBadges(globals.defaultIdClient);
		this.getClientStatsGraphicalLine(globals.defaultIdClient);
		this.getClientStatsIntersDataTable(globals.defaultIdClient);
		document.getElementById('clientStatsInterventionsListRefresher').setAttribute('onclick', `App.getClientStatsIntersDataTable(${globals.defaultIdClient})`)
		const aWeekAgo = new Date(new Date().setDate(new Date().getDate()-7));
		const litepickerRangePlugin = document.getElementById('litepickerRangePlugin');
		if (litepickerRangePlugin) {
			new Litepicker({
				element: litepickerRangePlugin,
				startDate: aWeekAgo,
				endDate: new Date(),
				singleMode: false,
				numberOfMonths: 2,
				numberOfColumns: 2,
				format: 'DD/MM/YYYY',
				delimiter: ' > ',
				lang: 'fr-FR',
				tooltipText: {
					one: 'jour',
					other: 'jours'
				},
				tooltipNumber: (totalDays) => {
					return totalDays;
				},
				plugins: ['ranges'],
				ranges: {
					customRanges: {
						'Aujourd\'hui': [new Date(), new Date()],
						'Hier': [new Date(new Date().setDate(new Date().getDate()-1)), new Date(new Date().setDate(new Date().getDate()-1))],
						'Derniers 7 Jours': [aWeekAgo, new Date()],
						'Derniers 30 jours': [new Date(new Date().setDate(new Date().getDate()-29)), new Date()],
						'Ce Mois': [new Date(new Date().setDate(1)), new Date()],
						'Dernier Mois': [new Date(new Date().getFullYear(), new Date().getMonth()-1, 1), new Date(new Date().getFullYear(), new Date().getMonth(), 0)],
						'Cette Année': [new Date(new Date().getFullYear(), 0, 1), new Date()],
						'Année 365': [new Date(new Date().setDate(new Date().getDate()-364)), new Date()],
					},
					// customRangesLabels:["Aujourd'hui","Hier","Derniers 7 Jours","Derniers 30 jours","Ce Mois","Dernier Mois","Cette Année"],
				},
				setup: (picker) => {
					picker.on('selected', (dateStart, dateEnd) => {
						App.refreshClientStats();
					});
				},
			});
		}
		// Building meta tags list...
		$('head title').text('Statistiques Clients - GPI SNS SOLUTIONS');
		$('head meta[name="description"]').text('SNS SOLUTION | Gestion de Parc Informatique | Statistiques Clients');
	},
	refreshClientStats: function() {
		let idClient = document.querySelector('[data-filter-stats-client-input]')?.value;
		const range = $('[data-filter-stats-range-input]').val();
		$('.dataTable').DataTable().destroy(true); // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
		if(idClient=='' && this.settings.clientsInfos.length > 0) { // All Sites stats then
			idClient = []
			this.settings.clientsInfos.forEach(element => {
				idClient.push(element.idClient);
			});
		}
		this.getClientStatsBadges(idClient, range);
		this.getClientStatsGraphicalLine(idClient, range);
		this.getClientStatsIntersDataTable(idClient, range);
	},
	getClientStatsGraphicalLine: function(idClient, myRange = null) {
		if(globals.myLineChart) globals.myLineChart.destroy();
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, idClient: idClient, range: myRange, req: 'getClientStatsGraphicalLine'}, function(data){
			App.myLineChartConfig.datasets[0].data = data.lineDataClosed
			App.myLineChartConfig.datasets[1].data = data.lineDataCreated
			globals.myLineChart = new Chart(document.getElementById("myAreaChart"), {
				type: "line",
				data: {
					labels: data.lineLabels,
					...App.myLineChartConfig,
				}
			});
		}, "json");
	},
	getClientStatsBadges: function(idClient, myRange) {
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, idClient: idClient, range: myRange, req: 'getClientStatsBadges'}, function(data){
			$("#badgeClientStatsCreatedTickets").html(data.clientsCreatedInterventions);
			$("#badgeClientStatsClosedTickets").html(data.clientsClosedInterventions);
			$("#badgeClientStatsOpenTickets").html(data.clientsOpenInterventions);
			$("#badgeClientStatsTicketsTime").html(data.clientsInterventionsTime);
			$("#badgeClientStatsTicketsTimeMedian").html(data.clientsInterventionsTimeMedian);
		}, "json").done(function(data) {
		});
	},
	getClientStatsIntersDataTable: function(idClient, myRange) {
		$('.dataTable').DataTable().destroy(true); // Make sure previous DataTables are Destroyed => fixedHeader bug fix !
		$.post(globals.serverAddress, {id: globals.id, type: globals.type, pwd: globals.pwd, site: globals.site, idClient: idClient, range: myRange, req: 'getClientStatsIntersDataTable'}, function(data){
			$("#clientStatsInterventionsListCont").html(data.snippet);
			$("#clientStatsInterventionsMobileCont").html(data.snippetPhone);
		}, "json").done(function(data) {
			App.buildDataTables('#dataTableClientStatsIntersList', 100, true, 0, 'desc', 'SNS-GPI interventions client', [0,1,3,5,6], [2,4,7,8], '', '', '', [0,1,2,3,4,5,6,7,8], true);
		}).always(function(){
			// App.getFormsSelectBoxes(['#modClientForm', '#addClientForm'], 'interventionsController', ['AgenceGestionClient','AgenceFacturationClient']);
		});
	},
	myLineChartConfig: {
		// labels: [],
		datasets: [{
			label: "Clôtures",
			lineTension: 0.3,
			backgroundColor: "rgba(105, 0, 199, 0.05)",
			borderColor: "rgba(8, 159, 227, 1)",
			pointRadius: 3,
			pointBackgroundColor: "rgba(8, 159, 227, 1)",
			pointBorderColor: "rgba(8, 159, 227, 1)",
			pointHoverRadius: 3,
			pointHoverBackgroundColor: "rgba(8, 159, 227, 1)",
			pointHoverBorderColor: "rgba(8, 159, 227, 1)",
			pointHitRadius: 10,
			pointBorderWidth: 2,
			fill: 'origin',
			data: []
		}, {
			label: "Créations",
			lineTension: 0.3,
			backgroundColor: "rgba(255, 193, 71, 0.05)",
			borderColor: "rgba(240, 125, 1, 1)",
			pointRadius: 3,
			pointBackgroundColor: "rgba(240, 125, 1, 1)",
			pointBorderColor: "rgba(240, 125, 1, 1)",
			pointHoverRadius: 3,
			pointHoverBackgroundColor: "rgba(240, 125, 1, 1)",
			pointHoverBorderColor: "rgba(240, 125, 1, 1)",
			pointHitRadius: 10,
			pointBorderWidth: 2,
			fill: 'origin',
			data: []
		}]
	},
	options: {
		responsive: true,
		maintainAspectRatio:false,
		legend:{'display':true, position: 'bottom'},
		layout: {
			padding: {
				left: 10,
				right: 25,
				top: 25,
				bottom: 0
			},
			// height: 460,
		},
		scales: {
			x: {
				time: {
					unit: "date"
				},
				gridLines: {
					display: false,
					drawBorder: false
				},
				ticks: {
					maxTicksLimit: 7
				}
			},
			y: {
				ticks: {
					maxTicksLimit: 5,
					padding: 10,
					// Format numbers in the ticks
					callback: function(value, index, values) {
						return App.number_format(value, 2, ',', ' ');
					}
				},
				gridLines: {
					color: "rgb(234, 236, 244)",
					zeroLineColor: "rgb(234, 236, 244)",
					drawBorder: false,
					borderDash: [2],
					zeroLineBorderDash: [2]
				}
			}
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: function(context) {
						let label = context.dataset.label || '';
						if (context.parsed.y !== null) {
							label += ": " + App.number_format(context.parsed.y, 2, ',', ' ');
							// label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
						}
						return label;
					}
				}
			}
		}
	},
	buildMyDropzone: function(myFormDiv, callBackFunction, ajaxController, ajaxRequest, isModal) {
		// Dropzone.options.myDropzone = {
		console.log("buildMyDropzone => "+ajaxRequest+' | '+myFormDiv);
		$(myFormDiv+' #my-dropzone').dropzone({
			url: globals.serverAddress,
			paramName: "files_d", // name of file input (defaults to file) here with uploadMultiple it will be files_d[]
			autoProcessQueue: false,
			uploadMultiple: true,
			parallelUploads: 10,
			maxFiles: 10,
			maxFilesize: 20,
			// acceptedFiles: "image/*,application/pdf",
			addRemoveLinks: true,
			dictFileTooBig: "Ce fichier est trop volumineux !",
			dictMaxFilesExceeded: "Nous limitons l'envoi à 10 documents",
			dictInvalidFileType: "Type de fichier non accepté.",
			dictCancelUpload: "Annuler",
			dictUploadCanceled: "Annulé",
			dictCancelUploadConfirmation: "Voulez-vous vraiment annuler ?",
			dictRemoveFile: "<i class='fa fa-times-circle'></i> Effacer",
			dictRemoveFileConfirmation: "Voulez-vous vraiment effacer ?",
			dictResponseError: "Suite à une erreur technique votre demande n'a pas été traitée.",
			headers: {
				'Cache-Control': null,
				'X-Requested-With': null,
			},
			init: function () {
				var wrapperThis = this;
				var errorMeOnce = '';

				$(myFormDiv+' #sender').on("click", function (e) {
					e.preventDefault();
					e.stopPropagation();
					var validForm = $(myFormDiv)[0].checkValidity();
					// var validDescription = document.getElementById("description").checkValidity();
					if (!validForm) {
						// Here you can handle the empty input case, color the inputs red or whatever
						$(myFormDiv+' #successfail').html('<div class="alert alert-danger" role="alert"<i class="fa fa-times-circle me-1"></i>Veuillez vérifier les champs entourés en rouge ci-dessus</b></div>');
						return false;

					}
					else {
						if(wrapperThis.getAcceptedFiles().length<=0) {
							console.warn('No Dropzone switch to callBackFunction => '+ajaxRequest);
							callBackFunction(myFormDiv, isModal); // If dropzone is empty we make the call without it !
							// App.addIntervention('#addBordereauxForm'); // If dropzone is empty we make the call without it !
							// alert("Veuillez sélectionner au moins un document à transférer"); return false;
						}
						else { // All form is properly filled and at least one file is to be uploaded...
							$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
							$(myFormDiv+' #my-dropzone').fadeTo('slow',0.6);
							$(myFormDiv+' #my-dropzone').append('<div id="disablingDrozone" style="position: absolute;top:0;left:0;width: 100%;height:100%;z-index:2;opacity:0.4;filter: alpha(opacity = 50)"></div>');
							wrapperThis.processQueue();
						}
					}
				});
				/*
				this.on("addedfile", function (file) {
					// Create the remove button
					var removeButton = Dropzone.createElement("<button class='btn btn-danger btn-sm'><i class='fa fa-times-circle'></i> Effacer</button>");
					// Listen to the click event
					removeButton.addEventListener("click", function (e) {
						// Make sure the button click doesn't submit the form:
						e.preventDefault();
						e.stopPropagation();
						// Remove the file preview.
						wrapperThis.removeFile(file);
						// If you want to the delete the file on the server as well,
						// you can do the AJAX request here.
					});
					// Add the button to the file preview element.
					file.previewElement.appendChild(removeButton);
				});
				*/
				this.on('sendingmultiple', function (data, xhr, formData) {
					$(myFormDiv+' #progressCont').html('<div class="progress mb-4" style="height: 2rem;"><div class="progress-bar bg-warning progress-bar-striped progress-bar-animated" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100" style="width: 1%;"><span class="sr-only">1%</span></div></div>');
					// formData = new FormData($(myFormDiv)[0]);
					formData.append("req", ajaxController);
					formData.append("action", ajaxRequest);
					formData.append("id", globals.id);
					formData.append("type", globals.type);
					formData.append("pwd", globals.pwd);
					formData.append("name", "Client Application");
					formData.append("firstname", globals.login);
					// $(myFormDiv).find("input[type=text], input[type=hidden], input[type=tel], input[type=email], input[type=number], input[type=file], input[type=password], input[type=date], input[type=datetime-local], textarea, select").each(function () {
					$(myFormDiv).find("input, textarea, select").not("input[type=checkbox]").not("input[type=radio]").each(function () {
						formData.append(this.name, $(this).val());
					});
					$(myFormDiv).find("input[type=checkbox]").each(function () {
						formData.append(this.name, Number(this.checked));
					});
					$(myFormDiv).find("input[type=radio]").each(function () {
						formData.append(this.name, Number(this.checked));
					});
					/*
					$(myFormDiv+' textarea').each(function () {
						formData.append($(this).attr('name'), $(this).val());
					});
					*/
					//formData.append("Username", $("#Username").val());
				});
				this.on('uploadprogress', function (file, progress, bytesSent) {
					$(myFormDiv+' #progressCont').html('<div class="progress my-4" style="height: 2rem;"><div class="progress-bar bg-primary progress-bar-striped progress-bar-animated" aria-valuenow="'+progress+'" aria-valuemin="0" aria-valuemax="100" style="width: '+progress+'%;"><span class="sr-only">'+progress+'%</span></div></div>');
				});
				this.on('error', function (file, errorMessage, xhr) {
					if(errorMeOnce.indexOf(file.name) === -1) $(myFormDiv+' #successfail').append('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Le fichier '+file.name+' n\'a pas été envoyé.<br>Erreur: '+errorMessage+'.</b></div>');
					errorMeOnce += file.name;
					console.warn('Erreur pour le fichier '+file.name+' : '+errorMessage+' / '+xhr);
					$(myFormDiv+' #progressCont').empty();
					wrapperThis.removeAllFiles();
				});
				this.on('successmultiple', function (file, response) {
					let jsonResponse = App.safeJsonParse(response);
					if(jsonResponse.ok == "ok") {
						$(myFormDiv+' #successfail').html('<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>L\'intervention a bien été créée ou modifiée.</b></div>');
						// if(window.location.hash.indexOf('bordereaux') !== -1) App.getBordereauxList(); // Only refresh table if it's on the page.
						if(isModal) {
							setTimeout(function(){
								$(myFormDiv).closest('.modal').find('.btn-close').trigger('click');
								App.clearFormFields(myFormDiv);
								// Set #Probleme field back to default and empty out assignments areas...
								$(myFormDiv+' #Probleme').val(globals.defaultProblem);
								wrapperThis.removeAllFiles();
								if(ajaxController=='addIntervention') $('#interventionsListRefresher').trigger('click'); // Refresh interventions list
								if(ajaxController=='modIntervention') $('#interventionPageRefresher').trigger('click'); // Refresh interventionPage
							}, 3600);
						}
						else {
							wrapperThis.removeAllFiles();
							setTimeout(function(){
								location.reload();
							}, 2600);
						}
					}
					else {
						$(myFormDiv+' #successfail').html('<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>L\'intervention n\'a pas été créée ou modifiée, suite à un problème technique.</b></div>');
						wrapperThis.removeAllFiles();
					}
					//alert('Success: '+ jsonResponse);
				});
				this.on('completemultiple', function (file, jsonResponse) {
					//It is like jQuery always
					$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-save me-1"></i>Enregistrer');
					$(myFormDiv+' #my-dropzone').fadeTo('slow',1);
					$('#disablingDrozone').remove();
					$(myFormDiv+' #progressCont').empty();
				});
				/*
				// To access only accepted files count (answer of question)
				this.getAcceptedFiles().length
				// To access all files count
				this.files.length
				// To access all rejected files count
				this.getRejectedFiles().length
				// To access all queued files count
				this.getQueuedFiles().length
				// To access all uploading files count
				this.getUploadingFiles().length
				*/
			}
		});
		// };
		//var myDropzone = new Dropzone(myFormDiv+' #my-dropzone', myDropzoneOptions);
		// Dropzone.discover();
	},
	setAccountPage: function() {
		this.accountFilterClient()
	},
	accountFilterClient: function(thisBox) {
		const myFormDiv = '#modAccountForm'
		const idClient = (thisBox) ? thisBox.value : $('#clientsBoxAccount').val();
		$('#clientsAccountAlertCont').removeClass('d-block').addClass('d-none');
		if(idClient!='') {
			$('#clientsAccountCont').removeClass('d-none').addClass('d-block');
			$('#clientsAccountDisclaimer').removeClass('d-block').addClass('d-none');
			// Finding the client in array of object...
			const thisClient = globals.clientsInfos.find(item => item.idClient == idClient);
			// const foundIndex = globals.cart.findIndex(item => item.idClient == idClient);
			if(Object.entries(thisClient).length > 0) {
				for (const [key, value] of Object.entries(thisClient)) {
					$(myFormDiv+' #'+key).val(value);
					let target = $(myFormDiv+' #'+key);
					if(target.is('input[type=checkbox]') && value==1) $(myFormDiv+' #'+key).prop('checked', true); // Dealing with Checkboxes
				}
			}
			let alertInfos = '';
			if(thisClient.DateRenouvellementAntivirusClient!='') alertInfos += `<b>Date de renouvellement de l'Antivirus : ${thisClient.DateRenouvellementAntivirusClient}</b><br>`;
			if(thisClient.DateFinLicence3CX!='') alertInfos += `<b>Date de Fin de la Licence 3CX : ${thisClient.DateFinLicence3CX}</b><br>`;
			// if(thisClient.DateMaintenanceClient!='') alertInfos += `<b>${thisClient.DateMaintenanceClient}</b><br>`;
			if(alertInfos!='') {
				$('#clientsAccountAlert').html(alertInfos);
				$('#clientsAccountAlertCont').removeClass('d-none').addClass('d-block');
			}
		}
		else {
			$('#clientsAccountCont').removeClass('d-block').addClass('d-none');
			$('#clientsAccountDisclaimer').removeClass('d-none').addClass('d-block');
		}
	},
	modAccount: function(myFormDiv) {
		$(myFormDiv+' #sender').attr("disabled", true).html('<i class="fa fa-spinner fa-pulse me-1"></i>Veuillez patienter');
		let query = $(myFormDiv).serialize();
		query = query + "&id=" + globals.id + "&type=" + globals.type + "&pwd=" + globals.pwd + "&req=modAccount";
		let returns = "";
		$.post(globals.serverAddress, query, function(data){
			if(data.ok=="ok") {
				returns = '<div class="alert alert-success" role="alert"><b><i class="fa fa-check-circle me-1"></i>Votre compte a bien été mis à jour.</b></div>';
				App.reloadAccount();
			}
			else
				returns = '<div class="alert alert-danger" role="alert"><b><i class="fa fa-times-circle me-1"></i>Votre compte n\'a pas été modifié suite à un problème technique.</b></div>';
		}, "json").always(function(data){
			$(myFormDiv+' #sender').attr("disabled", false).html('<i class="fa fa-check-circle me-1"></i>Enregistrer');
			$(myFormDiv+' #successfail').html(returns);
		});
	},
	reloadAccount: function () {
		$.post(globals.serverAddress, {id: globals.id, login: globals.login, pwd: globals.pwd, req: 'reloadAccount'}, function(data){
			if(data.ok=="ok") {
				// Every things is ok so we refresh globals and we kick things off...
				$.localStorage.setItem('login', data.login);
				$.localStorage.setItem('pwd', data.pwd);
				$.localStorage.setItem('id', data.id);
				$.localStorage.setItem('active', data.active);
				$.localStorage.setItem('type', data.type);
				$.localStorage.setItem('mail', data.mail);
				$.localStorage.setItem('created', data.created);
				$.localStorage.setItem('lastmod', data.lastmod);
				$.localStorage.setItem('lastcon', data.lastcon);
				$.localStorage.setItem('clients', data.clients);
				$.sessionStorage.setItem('clientsInfos', data.clientsInfos);
				setTimeout(function(){
					App.refreshGlobals(data);
				}, 100);
			}
		}, "json").fail(function(){
		});
	},
	makeNiceDateTime: function(d) {
		const jsDay = d.getDate();
		const jsMonth = d.getMonth()+1; // January is zero
		const jsHours = d.getHours();
		const jsMinutes = d.getMinutes();
		const jsSeconds = d.getSeconds();
		const niceDay = (jsDay<10) ? '0'+jsDay : jsDay;
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceSeconds = (jsSeconds<10) ? '0'+jsSeconds : jsSeconds;
		const niceMonth = (jsMonth<10) ? '0'+jsMonth : jsMonth;
		const niceDate = niceDay+'/'+niceMonth+'/'+d.getFullYear()+' '+niceHours+':'+niceMinutes+':'+niceSeconds;
		return niceDate;
	},
	makeNiceTime: function(timeInMinutes) {
		const jsHours = Math.floor(timeInMinutes/60);
		const jsMinutes = timeInMinutes-jsHours*60;
		console.debug(jsMinutes);
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		return niceTime;
	},
	makeNiceTime24: function(timeInMinutes, offset) {
		const tempDate = new Date(Math.floor(timeInMinutes*60*1000)); // UNIX Timestamp here is GMT+1 Winter time so an hour is added
		const jsHours = Math.floor(tempDate.getHours()-offset);
		const jsMinutes = tempDate.getMinutes();
		const niceHours = (jsHours<10) ? '0'+jsHours : jsHours;
		const niceMinutes = (jsMinutes<10) ? '0'+jsMinutes : jsMinutes;
		const niceTime = niceHours+'h'+niceMinutes;
		console.warn(tempDate+' - '+niceTime+' - '+Math.floor(timeInMinutes*60*1000));
		return niceTime;
	},
	convertStringToDate: function(niceDateTime) { // See makeNiceDateTime => convertStringToDate("19/04/2022 11:20:30")
		let dateComponents = niceDateTime.split(' ');
		let datePieces = dateComponents[0].split("/");
		let timePieces = dateComponents[1].split(":");
		return(new Date(datePieces[2], (datePieces[1] - 1), datePieces[0], timePieces[0], timePieces[1], timePieces[2]));
	},
	printDiv: function(divName){
		var printContents = document.getElementById(divName).innerHTML;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	},
	btnCheckThatBox: function(thisBtn) {
		const box2Check = $(thisBtn).prev('input[type="checkbox"]');
		let state = $(box2Check).is(':checked');
		state = !state;
		$(box2Check).attr('checked', state);
	},
	fullScreen: function(){
		$('header').hide();
		$('.mainContent').removeClass('mt-n10');
		$('#fullScreenBack').show();
	},
	fullScreenBack: function(){
		$('header').show();
		$('.mainContent').addClass('mt-n10');
		$('#fullScreenBack').hide();
	},
	getScrollXY: function () {
		var scrOfX = 0, scrOfY = 0;
		if( typeof( window.scrollY ) == 'number' ) {
			//Netscape compliant
			scrOfY = window.scrollY;
			scrOfX = window.scrollX;
		} else if( document.body && ( document.body.scrollLeft || document.body.scrollTop ) ) {
			//DOM compliant
			scrOfY = document.body.scrollTop;
			scrOfX = document.body.scrollLeft;
		} else if( document.documentElement && ( document.documentElement.scrollLeft || document.documentElement.scrollTop ) ) {
			//IE6 standards compliant mode
			scrOfY = document.documentElement.scrollTop;
			scrOfX = document.documentElement.scrollLeft;
		}
		return [ scrOfX, scrOfY ];
	},
	getDocHeight: function () {
		var D = document;
		return Math.max(
			D.body.scrollHeight, D.documentElement.scrollHeight,
			D.body.offsetHeight, D.documentElement.offsetHeight,
			D.body.clientHeight, D.documentElement.clientHeight
		);
	},
	validateMail: (myMail = '') => {
		const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		return validRegex.test(myMail);
	},
	redirectOnUserAgent: function() {
		const mapping = {
			// scheme : market://details?id=<package_name>
			'android': 'market://details?id=fr.snsolutions.app',
			//scheme : itms-apps://itunes.apple.com/app/id<numeric_app_id>
			'iphone': 'itms-apps://itunes.apple.com/app/id6443960143',
			// if availables :
			'ipad': 'itms-apps://itunes.apple.com/app/id6443960143',
			'ipod':'itms-apps://itunes.apple.com/app/id6443960143',
		}
		const userAgent = navigator.userAgent.toLowerCase();
		let redir = false;
		for (var dev in mapping) {
			if (userAgent.search(dev) != -1) {
				redir = true;
				window.location = mapping[dev];
				return;
			}
		}
		if (!redir)
		{
			setTimeout(function(){
				App.popModal('modalRedirStore');
			}, 1000);
			setTimeout(function(){
				window.location = 'https://app.snsolutions.fr';
			}, 3600);
		}
	},
};

// Expose App object to window object => need this to use click event in html
window.App = App;
// require('./router.js');

(function() {
	if (document.URL.indexOf( 'stores.html' ) !== -1) {
		App.redirectOnUserAgent(); // It's store download page.
	}
	else {
		App.init();
	}
})();